/* General */
:root {
  --background-color: #f2f4f6;
  --headline-color: #00214d;
  --paragraph-color: #2d334a;
  --button-color: #ffd803;
  --button-text-color: #272343;
  --illustration-stroke-color: #272343;
  --main-color: #fffffe;
  --highlight-color: #ffd803;
  --secondary-color: #e3f6f5;
  --tertiary-color: #bae8e8;
  --font-primary: 'Montserrat', sans-serif;
  --font-secondary: 'Open Sans', sans-serif;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--paragraph-color);
  font-family: var(--font-primary);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  color: var(--headline-color);
}

h1,
h2,
h3 {
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

h4,
h5,
h6 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;

}

a {
  color: var(--white);
  text-decoration: none;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

code {
  color: var(--emerald);
}

#root {
  height: 100%;
  width: 100%;
  background-color: #f2f4f6;

}

@media only screen and (max-width: 640px) {
  .mobile-scroll-lock {
    overflow: hidden;
  }
}

/* styles/components/protected-page.css */

.ratings-section,
.last30 {
  background-color: var(--background-color);
}

.last30.standard-border-box-shadow {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Replace with the standard style */
}

.last30title {
  background-color: var(--headline-color);
  color: var(--background-color);
  margin: 0;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.last30reviews,
.last30_5star,
.last30_1star {
  font-size: 2rem !important;
  margin-bottom: 0.5rem !important;
  color: var(--paragraph-color);
}

.last30_2star,
.last30_3star,
.last30_4star {
  font-size: 2rem !important;
  margin-bottom: 0.5rem !important;
  color: var(--paragraph-color);
}


.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.css-1s50f5r {
  margin-top: 0 !important;
}

.css-1lhzw4j {
  font-size: unset !important;
  line-height: unset !important;
  font-family: unset !important;
  font-weight: unset !important;
  font-family: var(--font-secondary) !important;
  font-size: 2.8rem !important;
  font-weight: bold !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.last30.css-aoeo82 {
  text-align: center !important;
  border-radius: 0 !important;

}

.standard-border-box-shadow {
  border: 1px solid #1b2d45 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.h2-header {
  background-color: #00214d !important;
  background-color: var(--headline) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2) !important;
  color: #fffffe !important;
  color: var(--background) !important;
  margin: 0 !important;
  padding: 1.75rem !important;
  text-align: center !important;
  width: 100% !important;
}

.btn-autogenerate {
  cursor: alias;
  background-color: #00ebc7;

}

.btn-edit {
  cursor: pointer;
  background-color: var(--secondary-color);

}

.modal-save-button {
  background-color: #00ebc7;
}

.modal-cancel-button {
  background: #ff5470;
  color: #fff;
}

/* src/styles/GoogleProfile.css */

:root {
  --background-color: #fffffe;
  --headline-color: #00214d;
  --paragraph-color: #1b2d45;
  --button-color: #00ebc7;
  --button-text-color: #00214d;
  --border-color: #ccc;
  --box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
  --font-primary: Montserrat, sans-serif;
  --font-secondary: Open Sans, sans-serif;
}

.google-profile h1,
.google-profile h2,
.google-profile h3,
.google-profile h4,
.google-profile h5,
.google-profile h6 {
  font-family: var(--font-secondary);
  color: var(--headline-color);
}

.generated-by-google__header,
.manage-profile__header {
  padding: 5px 10px;
  margin: 0 !important;
  width: 100%;
  color: #fff;
}

.description-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  background-color: #dcdcdc;
  border: 1px solid var(--border-color);
}

.description-button {
  flex: 1;
  margin: 5px;
}

/* src/styles/ManageProfile.css */

.manage-profile__header {
  background-color: #00214d;
  color: #fffffe;
  width: 100%;
  margin: 0 !important;
  padding: 5px 10px;
  font-family: 'Open Sans', sans-serif;
}

.description-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  background-color: #fde24f;
  border: 1px solid #1b2d45;
}

.description-button {
  flex: 1;
  margin: 5px;
  color: #00214d;
}

.modal {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--secondary-color);
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.modal form {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.description-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #1b2d45;
}

.modal.active {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 80%;
  min-height: 40vh;
}

.modal.active p {
  border: unset;
}

input[type="text"] {
  width: 100%;
}

.textarea-description {
  width: 100%;
  border: 1px solid #000;
  padding: 10px;
}

/* src/styles/GeneratedByGoogle.css 
 */
.generated-by-google__header {
  background-color: #00ebc7;
  color: #00214d;
  width: 100%;
  margin: 0 !important;
  padding: 5px 10px;
  font-family: 'Open Sans', sans-serif;
}

.google-profile p,
.manage-profile p,
.generated-by-google p {
  padding: 10px 5px 5px 10px;
  margin: 0px 0 0rem !important;
  font-family: var(--font-primary);
  color: var(--paragraph-color);
  border: 1px solid #1b2d45;
}

.generated-by-google,
.manage-profile {
  background-color: #fffffe;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

.ratings-h3 {
  text-align: center;
}

.description-label {
  font-weight: bold;
}

.employee-reviews .accordion-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.employee-reviews .employee-name,
.employee-reviews .average-rating {
  flex-basis: 50%;
  text-align: left;
}

.employee-reviews .average-rating {
  text-align: left;
}

.employee-name h6 {
  font-size: 1.5rem;
  font-weight: bold;
}

.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters.css-1iji0d4 {
  background-color: var(--secondary-color);
}

p.MuiTypography-root.MuiTypography-body1.css-9l3uo3 {
  font-size: 2rem !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.css-15kq27i {
  margin-bottom: 20px;
}

.rating-size {
  font-size: 2rem !important
}

div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2.css-o0rlmm>div>div {
  padding: 10px;
}

.alert-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alert-popup__content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 60vw;
}

.alert-popup__content p {
  margin-bottom: 15px;
  font-size: 2rem;

}

.alert-popup__content button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #ffd803;
  color: #fff;
  font-size: 2rem;
}

button.button__sign-up {
  display: none;
}

/* src/styles/Post.css */

.post-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20vh 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.post-heading {
  color: #0A2540;
  font-size: 4.5rem;
  margin-bottom: 20px;
}

.post-subheading {
  color: #2f8b1e;
  font-size: 3rem;
  margin-bottom: 40px;
}

.post-paragraph {
  color: #4F5D75;
  font-size: 1.75rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.post-box {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 20px;
  background-color: #F9F9F9;
  margin-top: 30px;
}

.post-button {
  background-color: #707e83;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 2rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.post-alert-message {
  margin-top: 10px;
  color: #00A86B;
  font-weight: bold;
}

.css-147ryct {
  max-width: 80vw !important;
}

.css-10yaf9w {
  width: 260px;
}