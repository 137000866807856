@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Montserrat:wght@400;500;600;700&display=swap");

/* Theme 

:root {
  --orange: #ff4f40;
  --indigo: #635dff;
  --white: #fff;
  --light-aluminium: #eaecee;
  --aluminium: #bdc4cf;
  --dark-aluminium: #2a2e35;
  --black: #000000;
  --yellow: #ebca40;
  --mandarine: #ff7f38;
  --pink: #ff44dd;
  --blue: #3885ff;
  --aqua: #3ec6eb;
  --emerald: #1bc99f;

  --yellow-mandarine-gradient: linear-gradient(
    153.07deg,
    var(--yellow) -2.47%,
    var(--mandarine) 102.78%
  );

  --mandarine-orange-gradient: linear-gradient(
    153.07deg,
    var(--mandarine) -2.47%,
    var(--orange) 102.78%
  );

  --pink-yellow-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--yellow) 102.78%
  );

  --pink-indigo-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--indigo) 102.78%
  );

  --indigo-aqua-gradient: linear-gradient(
    153.07deg,
    var(--indigo) -2.47%,
    var(--aqua) 102.78%
  );

  --blue-aqua-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--aqua) 102.78%
  );

  --aqua-emerald-gradient: linear-gradient(
    153.07deg,
    var(--aqua) -2.47%,
    var(--emerald) 102.78%
  );

  --emerald-yellow-gradient: linear-gradient(
    153.07deg,
    var(--emerald) -2.47%,
    var(--yellow) 102.78%
  );

  --font-primary: "Inter", sans-serif;
  --font-secondary: "Space Grotesk", sans-serif;
  --font-mono: "Fira Code", monospace;
}
*/

:root {
  --background-color: #fffffe;
  --headline-color: #00214d;
  --paragraph-color: #1b2d45;
  --button-color: #00ebc7;
  --button-text-color: #00214d;
  --stroke-color: #00214d;
  --main-color: #fffffe;
  --highlight-color: #00ebc7;
  --secondary-color: #ff5470;
  --tertiary-color: #fde24f;

  --font-title: "Open Sans", sans-serif;
  --font-body: "Montserrat", sans-serif;
}