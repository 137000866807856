@import "theme.css";
@import "general.css";
@import "components/index.css";

/* src/styles/components/AccountDropdown.css */

.account-dropdown,
.source-dropdown {
    max-width: 300px;
    width: 100%;
    margin: 20px auto;
    /* Centering the dropdown */
    padding: 10px;
    border: 1px solid #ccc;
    /* Neutral border color */
    border-radius: 5px;
    /* Smooth edges */
}

.account-dropdown select,
.source-dropdown select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    /* Consistent with the dropdown border */
    border-radius: 4px;
    /* Slightly rounded corners for the select */
    font-size: 16px;
    /* Legible font size */
    cursor: pointer;
    /* Indicate it is selectable */
    background-color: #fff;
    /* White background for clarity */
    -webkit-appearance: none;
    /* Disable native styling */
    -moz-appearance: none;
    appearance: none;
}

/* Labels within the AccountSettingsComponent */
.account-dropdown label,
.source-dropdown label {
    display: block;
    /* Ensure the label is on its own line */
    color: #333;
    /* Darker color for better readability */
    font-weight: bold;
    /* Emphasize the label */
    margin-bottom: 8px;
    /* Space between label and select */
    font-size: 16px;
}

/* Styles for the success modal */
.add-account-success-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

/* Hiding the previous and next pagination items */
.MuiPaginationItem-previousNext {
    display: none !important;
}

/* Default cursor for elements */
.css-1xr9krm {
    cursor: default;
}

/* Responsive adjustments */
@media (max-width: 600px) {

    .account-dropdown,
    .source-dropdown {
        max-width: none;
        /* Allow dropdowns to fill the width on small screens */
    }
}


/* Styles for the Google SSO button */


.gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: #f2f2f2;
    background-image: none;
    border: none;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity .218s;
    transition: opacity .218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.gsi-material-button:disabled {
    cursor: default;
    background-color: #ffffff61;
}

.gsi-material-button:disabled .gsi-material-button-state {
    background-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: #001d35;
    opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: #001d35;
    opacity: 8%;
}

/* Styles for the Google SSO button  - END */

/* response contact method input background  */

.css-1o6z5ng {
    background-color: #fff;
}

/* Settings fontfix  */
.css-15nru74 {
    font-size: 1.25rem !important;
}

.css-1f19pnt {
    font-size: 2rem !important;
}

.css-aq9oj3 {
    font-size: 1.25rem !important;
}

.css-9l3uo3 {
    font-size: 1.25rem !important;
}

.css-1y9c6uq {
    font-size: 1.25rem !important;
}

.css-hjvdj6 {
    font-size: 1rem !important;
}

.css-1o6z5ng {
    background-color: #fff !important;
}

.css-1y9c6uq::before {
    content: 'Tone: ';
}

#root>div>div.page-layout__content>div>div>div>div.MuiBox-root.css-1v3caum>div>div:nth-child(3)>div:nth-child(1)>div {
    display: none;
}

#root>div>div.page-layout__content>div>div>div>div.MuiBox-root.css-1v3caum>div>div:nth-child(2)>div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.negative-review-grid-item.css-iol86l {
    padding-left: 40px;
}

#root>div>div.page-layout__content>div>div>div>div.MuiBox-root.css-1v3caum>div>div:nth-child(3)>div:nth-child(2) {
    margin-top: -10vh;
}

/* Hide last 30 and alerts */

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-5.custom-grid-item.css-8e4lkk {
    display: none;
}

.MuiContainer-root.MuiContainer-maxWidthLg.css-1qsxih2 {
    display: none;
}

.MuiBox-root.css-3turkr {
    display: none;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.css-11hlwqc {
    display: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
    background-color: #3e8e41;
}

div.select-checkbox>span {
    font-size: 1.5rem;
}

.select-checkbox {
    background-color: greenyellow;
    padding: 0 10px !important;

}

.proposed-response-bubble {
    background-color: #daffda;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
    font-size: 1.5rem;
    margin-top: 10px;
    padding: 10px;
    width: 80%;
    border: 2px solid lawngreen;
}