.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f2f4f6;

}

.page-layout__content {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  /* margin-top: 8rem; navfix */

  width: 100%;
}

/* @media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
  }
} */