.nav-bar__container {
  /* position: fixed; */
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  z-index: 300;
  background-color: #ffffff;
}

.nav-bar {
  flex: 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 1200px;
  padding: 0 24px;
  margin: 0;
  font-family: var(--font-body);
}

.nav-bar__brand {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: -100px;
}

.nav-bar__link {
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-bar__logo {
  height: 50%;
}

.nav-bar__tabs {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-bar__tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--paragraph-color) !important;
  margin-right: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  transition: color 0.3s ease;

}

.nav-bar__tab:last-child {
  margin-right: 0;
}

.nav-bar__tab--active {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--highlight-color);
  text-decoration-thickness: 4px;
  text-underline-offset: 8px;
}

.nav-bar__tab:hover {
  color: var(--highlight-color) !important;
}

.nav-bar__icon {
  display: none;
}

.nav-bar__buttons {
  display: flex;
  margin-left: 24px;
}



@media only screen and (max-width: 640px) and (hover: none) {
  .mobile-nav-bar__tab:hover {
    color: var(--white);
  }

  .mobile-nav-bar__tab--active:hover {
    color: var(--white);
  }
}

@media only screen and (max-width: 640px) {
  .nav-bar__container {
    display: none;
  }

  .nav-bar__tab {
    font-size: 1.5rem;
  }

  .nav-bar {
    height: 6.4rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar__brand {
    display: flex;
    align-items: center;

  }

  .nav-bar__logo {
    height: 3.2rem;
  }

  .nav-bar__tab--active {
    text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar__label {
    display: none;
  }

  .nav-bar__icon {
    display: block;
    font-size: 3.2rem;
  }

  .nav-bar__buttons {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 340px) {
  .nav-bar__tab {
    font-size: 1.3rem;
  }

  .nav-bar {
    height: 6.4rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar__brand {
    display: flex;
    align-items: center;

  }

  .nav-bar__logo {
    height: 2.8rem;
  }

  .nav-bar__tab--active {
    text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar__tab:focus {
    outline: 2px solid var(--highlight-color);
  }


  .nav-bar__buttons {
    margin-left: 24px;
  }
}

/* Additional styles for secondary navigation bar */
.secondary-nav-bar__container {
  /* position: fixed; */
  top: 50px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  z-index: 250;
  background-color: var(--secondary-color);
  height: 50px;
}

.secondary-nav-bar {
  flex: 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 1200px;
  padding: 0 24px;
  margin: 0;
}

.secondary-nav-bar__tabs {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Updated to center */
  color: var(--white);
  /* Font color for the secondary nav */

}

.secondary-nav-bar__tab:hover {
  color: var(--blue);
  /* Hover color for the secondary nav */
}

.secondary-nav-bar__tabs a {
  color: var(--white) !important;
  /* Font color for the secondary nav */
}

.secondary-nav-bar__tabs a:hover {
  color: var(--blue);
  /* Hover color for the secondary nav */
}

.secondary-nav-bar__tab:hover,
.secondary-nav-bar__tab:focus {
  color: var(--highlight-color);
  /* Changed from blue for consistency */
}

body {
  font-family: var(--font-body);
}

.nav-bar__tab:last-child:hover {
  cursor: pointer;
}