.page-footer {
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  width: 100%;

  background-color: #001534;
  color: #b7c9e4;
  font-weight: 500;
  text-align: center;
  height: auto;

  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  /* Ensure it's above other content */
}


.page-footer-grid {
  display: grid;

  width: 100%;
  max-width: 120rem;

  /* responsive */

}

.page-footer-grid__info {
  display: grid;

  /* responsive */

  grid-template-columns: 1.5fr 1fr auto;
  column-gap: 2.4rem;
}

.page-footer-grid__brand {
  display: grid;

  justify-items: center;

  grid-template-columns: 1fr;
  margin: 1vh;
}

.page-footer-info__message {
  /* responsive */

  text-align: left;
}

.page-footer-message__headline {
  margin: 0 0 1.4rem;

  font-weight: 500;
}

.page-footer-message__description {
  margin-bottom: 0;

  letter-spacing: 0.016rem;
}

.page-footer-info__button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-color);
  /* Using brand button color */
  color: var(--button-text-color);
  /* Using brand button text color */
}

.page-footer__hyperlink:focus,
.page-footer-info__button:focus {
  outline: 2px solid var(--highlight-color);
  /* Using brand highlight color */
}

.page-footer-info__resource-list {
  display: grid;

  /* responsive */

  row-gap: 0.8rem;
}

.page-footer-info__resource-list-item {
  text-align: right;
}

.page-footer-brand {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.page-footer-brand__logo {
  width: 50px;
  height: 50px;

  margin-right: 12px;
}

.page-footer__hyperlink {
  letter-spacing: 0.001rem;
}

.page-footer__hyperlink,
.page-footer__hyperlink:active,
.page-footer__hyperlink:visited {
  color: var(--highlight-color);
  /* Using brand highlight color */
}

.page-footer__hyperlink:hover {
  color: var(--secondary-color);
  /* Using brand secondary color */
}

@media only screen and (max-width: 960px) {
  .page-footer {
    /* responsive */

    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .page-footer-grid {
    /* responsive */

    padding: 3.2rem;
  }

  .page-footer-grid__info {
    /* responsive */

    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .page-footer-info__message {
    /* responsive */

    text-align: center;
  }

  .page-footer-info__resource-list {
    /* responsive */

    grid-template-columns: 1fr 1fr;
    row-gap: 3.2rem;
    column-gap: 3.2rem;
  }

  .page-footer-info__resource-list-item:nth-child(odd) {
    text-align: right;
  }

  .page-footer-info__resource-list-item:nth-child(even) {
    text-align: left;
  }
}

@media only screen and (max-width: 480px) {
  .page-footer {
    /* responsive */

    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .page-footer-grid {
    /* responsive */

    padding: 3.2rem 1.6rem;
  }

  .page-footer-grid__info {
    /* responsive */

    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .page-footer-info__message {
    /* responsive */

    text-align: center;
  }

  .page-footer-info__resource-list {
    /* responsive */

    grid-template-columns: 1fr 1fr;
    row-gap: 3.2rem;
    column-gap: 3.2rem;
  }

  .page-footer-info__resource-list-item:nth-child(odd) {
    text-align: right;
  }

  .page-footer-info__resource-list-item:nth-child(even) {
    text-align: left;
  }
}